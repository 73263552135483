import {Image} from "../../../models/Room";
import {PropertyDTO} from "./PropertyDTO";

export class PropertyPatchDTO{
    pets: boolean;
    occupation: string;
    gender: number;
    id?:number;
    title: string;
    description:string;
    latitude:string;
    longitude:string;
    address: string;
    name:string;
    numberFlatmates:number;
    smoking:boolean;
    addressAdminArea1?:string
    addressAdminArea2?:string
    addressCountry?:string
    addressPostalTown?:string
    addressRoute?:string
    addressStreetNumber?:string
    allImages:Image[]
    smoobuId?:number
    advertisable:boolean
    amenities:string[]

    constructor({
                    pets, occupation,gender, id, title, description, latitude, longitude,
                    address, name, numberFlatmates, smoking, addressAdminArea1, addressAdminArea2,
                    addressCountry, addressPostalTown, addressRoute, addressStreetNumber, allImages, smoobuId, advertisable, amenities
    }:PropertyDTO) {
        this.pets = pets;
        this.occupation = occupation;
        this.gender = gender;
        this.id = id;
        this.title = title;
        this.description = description;
        this.latitude = latitude;
        this.longitude = longitude;
        this.address = address;
        this.name = name;
        this.numberFlatmates = numberFlatmates;
        this.smoking = smoking;
        this.addressAdminArea1 = addressAdminArea1;
        this.addressAdminArea2= addressAdminArea2;
        this.addressCountry = addressCountry;
        this.addressPostalTown= addressPostalTown;
        this.addressRoute = addressRoute;
        this.addressStreetNumber = addressStreetNumber;
        this.allImages = allImages;
        this.smoobuId = smoobuId
        this.advertisable = advertisable;
        this.amenities = amenities.map(am => `/api/property_amenities/${am.id}`)
    }
}
