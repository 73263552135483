import {FormDescriptor, FormElements} from "form-generator";
import * as Yup from "yup";

const elements:FormElements = [
    {
        accessor:"surname",
        type:"text",
        Header:"Surname"
    },
    {
        accessor:"email",
        type:"text",
        Header:"Email"
    }
]
const initialValues = {
    name:null,
    surname:null,
}
const validationSchema = Yup.object().shape({
});

const bookingsFilterFormDescriptor = new FormDescriptor({elements,validationSchema,initialValues})
export default bookingsFilterFormDescriptor
