import * as React from "react";
import {useEffect, useState} from "react";
import useCurrentUser from "../../../authentication/useCurrentUser";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {Button, Drawer} from "@mui/material";
import Dashboard from "../../../components/Dashboard/Dashboard";
import useGetCompanyInfo from "../../../api/company/useGetCompanyInfo";
import BuyCreditsDialog from "../BuyCredits/BuyCreditsDialog";
import {account, bookings, enquiries, myads, properties, users} from "../../../router/frontend-routes";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import MailIcon from '@mui/icons-material/Mail';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import {CometChatConversations, CometChatUIKit} from "@cometchat/chat-uikit-react";

const basicRoutes = [
    {icon: <HolidayVillageIcon />, text:"My ads", route:myads},
    {icon: <MailIcon />, text:"Enquiries", route:enquiries},
    {icon: <MailIcon />, text:"Bookings", route:bookings},
    {icon: <InfoIcon />, text:"Account", route:account},
    {icon: <HolidayVillageIcon />, text:"Properties", route:properties}
]
export default function AgentDashboard({content = <></>}:any){
    const currentUser = useCurrentUser()
    const routes = (currentUser.admin) ? [...basicRoutes,   {icon: <PeopleIcon />, text:"Users", route:users}]: basicRoutes;

    const {getCompanyInfo} = useGetCompanyInfo();
    const [open, setOpen] = useState(false)
    useEffect(()=>{getCompanyInfo().then(response =>{
        const UID = response.data.cometChatUserId; //Replace with your UID

        CometChatUIKit.getLoggedinUser().then((user) => {
            if (!user) {
                //Login user
                CometChatUIKit.login(UID).then((user) => {
                    //mount your app
                })
                    .catch(console.log);
            } else {
                //mount your app
            }
        });
    })},[])

    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toolbarContent = <ToolbarContent setOpenDrawer={()=>{setOpenDrawer(!openDrawer)}} openBuyCredits={()=>{setOpen(true)}}/>
    return <>
        <Dashboard content={content} toolbarContent={toolbarContent} routes={routes} />
        <BuyCreditsDialog open={open} setOpen={setOpen}/>
        <Drawer
            open={openDrawer}
            sx={{
                width: 300,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 300,
                    boxSizing: 'border-box',
                },
            }}
            anchor="right"
        >
            <div style={{marginTop:100}}>
                <CometChatConversations></CometChatConversations>
            </div>
        </Drawer>
    </>
}

const ToolbarContent = ({openBuyCredits, setOpenDrawer}:any) => {
    // @ts-ignore
    const {auth} = useSelector(state => state)
    return <>
        <Typography
            component="h6"
            variant="body2"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
        >
        </Typography>
        <div className={"d-flex align-items-center"}>
            <Typography
                component="h6"
                variant="body2"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, paddingX:3, marginX:3 }}
            >
                Credits {auth && auth.company && auth.company.credits}
            </Typography>
            <Button color={"secondary"} variant={"contained"} onClick={()=>openBuyCredits()}>Buy more</Button>
            <Button color={"secondary"} variant={"contained"} onClick={()=>setOpenDrawer()}>Open Chat</Button>
        </div>
    </>
}
