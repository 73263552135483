export function muoviSu(livelli:any[], index:number):any[]{
    const previousElements = livelli.slice(0,index-1)
    const myself = livelli[index]
    const mysubstitute = livelli[index-1]
    const nextElements = livelli.slice(index+1)
    return  [...previousElements, myself, mysubstitute, ...nextElements];
}

export function muoviGiu(livelli:any[], index:number):any[]{
    const previousElements = livelli.slice(0,index)
    const myself = livelli[index]
    const mysubstitute = livelli[index+1]
    const nextElements = livelli.slice(index+2)
    return [...previousElements, mysubstitute, myself, ...nextElements];
}
