import {Image} from "../../../../models/Room";
import {useParams} from "react-router";
import React, {ChangeEvent, useState} from "react";
import authClient from "../../../../api/authClient";
import {savePropertyPictures} from "../../../../router/api-routes";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    TextField
} from "@mui/material";
import {FileToUpload} from "../../Ads/Tabs/AdPictureUploadTab";
import {PropertyDTO} from "../../../../api/property/dto/PropertyDTO";
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import {muoviGiu, muoviSu} from "../../../../helpers/positionHelper";
import _ from "lodash";

const readFileAsync = async (file:File) => {
    const content = await readFile(file)
    return content

}

const readFile = (file:File) => new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = evt => resolve({filename:file.name,base64:reader.result, title:file.name})
    reader.readAsDataURL(file)
})

const readFiles = async (files:FileList) => {
    const fileListLength = files.length;
    const filesRead = []
    for (let i = 0; i < fileListLength; i++) {
        const file = await readFileAsync(files[i])
        filesRead.push(file)
    }
    return filesRead;
}



interface AdPictureUploadInterface{
    property?:PropertyDTO,
    getProperty: ()=>void
}

export default function PropertyPicturesTab({property, getProperty}:AdPictureUploadInterface){

    const params = useParams()
    const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([]);

    const [images, setImages] = useState(_.sortBy(property?.allImages?? [],"position"))

    // @ts-ignore
    const onSubmit = () => {
        // @ts-ignore
        const finalImages = [...images.map((im,index) => {
            im.position = index;
            return im
        }), ...filesToUpload]
        authClient.patch(savePropertyPictures(params.id), {images:finalImages} ).then(response=> {
            getProperty()
        })
    }

    const fileHandleChange= (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const files = e.target.files;
        // @ts-ignore
        readFiles(files).then(result => setFilesToUpload(result))

    };

    const [open,setOpen]= useState(false)
    const [currentImage, setCurrentImage] = useState<Image|undefined>()
    const onMoveUp = (index:number) => {
        const newImages = muoviSu(images,index)
        setImages(newImages)
    }

    const onMoveDown = (index:number) =>{
        const newImages = muoviGiu(images,index)
        setImages(newImages)
    }

    return property ? <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>

                <List className={"w-100"} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {images.map((image,position) => <ListItem key={image.id} className="mb-3 w-100" style={{border:"1px solid #004862", borderRadius:12}}>
                        <img className="me-3" height={100} src={`${image.url}?w=164&h=164&fit=crop&auto=format`}
                             alt={image.filename}
                             loading="lazy"
                             onClick={() => {
                                 setCurrentImage(image)
                                 setOpen(true)
                             }}
                        />
                        <IconButton disabled={position===0}  aria-label="up" onClick={()=>{
                            onMoveUp(position)
                        }}>
                            <ArrowCircleUpRoundedIcon></ArrowCircleUpRoundedIcon>
                        </IconButton>
                        <IconButton disabled={position===images.length-1} aria-label="down" onClick={()=>{
                            onMoveDown(position)
                        }}>
                            <ArrowCircleDownRoundedIcon></ArrowCircleDownRoundedIcon>
                        </IconButton>
                    </ListItem>)}
                </List>

                <Dialog
                    open={open}
                    onClose={()=>setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xl"
                >
                    <DialogTitle id="alert-dialog-title">
                        {currentImage && currentImage.filename}
                    </DialogTitle>
                    <DialogContent>
                        {currentImage && <img
                            width="100%"
                            src={`${currentImage.url}?w=164&h=164&fit=crop&auto=format`}
                            alt={currentImage.filename}
                            loading="lazy"
                        />}
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} onClick={()=>setOpen(!open)}>Close</Button>
                        <Button variant={"contained"} onClick={()=>{}}>Remove</Button>
                    </DialogActions>
                </Dialog>
                <TextField
                    fullWidth
                    type="file"
                    id="files"
                    inputProps={{ multiple: true }}
                    name="files"
                    onChange={fileHandleChange}

                />
            </Grid>
            <Grid item xs={6}>
                <Button variant={"contained"} onClick={onSubmit}>Save</Button>
            </Grid>
        </Grid>
    </div> : <div></div>
}
