export interface PaymentStatusInterface{
    status?: number
}

type PaymentStatusValue = "Paid" | "Not paid" | "Unknown"

export class BookingPaymentStatus{
    status: PaymentStatusValue

    constructor({status}:PaymentStatusInterface) {
        switch(status){
            case 0:{
                this.status = "Paid";
                break;
            }
            case 1:{
                this.status = "Not paid";
                break;
            }
            default:{
                this.status = "Unknown"
                break;
            }
        }
    }
}
