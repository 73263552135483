import React, {useEffect, useMemo, useState} from 'react'
import {PropertyDTO} from "../../../../api/property/dto/PropertyDTO";
import authClient from "../../../../api/authClient";
import {getPropertyPortalInfos, getSmooubuPropertiesRoute} from "../../../../router/api-routes";
import useCurrentUser from "../../../../authentication/useCurrentUser";
import {
    FormDescriptor,
    FormElement,
    FormElements,
    FormGeneratorContext,
    FormGeneratorContextProvider
} from "form-generator";
import {saveProperty} from "../../../../api/property/propertyApi";
import {toast} from "react-toastify";
import {Button} from "@mui/material";

interface PropertyPortalsTabInterface{
    property: PropertyDTO
    updateProperty:()=>void
}

interface RoomPortalInfo{
    id:number,
    roomPortalId:string
}

interface PropertyPortalInfo{
    id: number,
    portalSubscription: {
        portal:{
            name: string
        }
    },
    propertyPortalId: string
    roomPortalInfos:RoomPortalInfo[]

}

const smoobuFormDescriptorFormElements:FormElements = [
    {
        type:"select",
        accessor: "smoobuId",
        Header: "Smoobu property",
        options:[]
    }
]

const smoobuFormDescriptor = new FormDescriptor({elements:smoobuFormDescriptorFormElements, initialValues: {smoobuId: null}})

interface Apartment{
    id: number,
    name:string
}
interface GetSmoobuProperties{
    apartments:Apartment[]
}

const useSmoobuProperties = () =>{
    const [smoobuProperties,setSmoobuProperties] = useState<Apartment[]>([])
    const getSmoobuProperties = (companyId:string) => authClient.get(getSmooubuPropertiesRoute(companyId)).then(response => {
        const data: GetSmoobuProperties = response.data
        setSmoobuProperties(response.data.apartments)
    })

    return {smoobuProperties, getSmoobuProperties}
}


export default function PropertyPortalsTab({property,updateProperty}:PropertyPortalsTabInterface){

    const [propertyPortalInfos,setPropertyPortalInfos] = useState<PropertyPortalInfo[]>([])
    const currentUser = useCurrentUser();
    const getPropertyPortalInfo = (propertyId:string) => authClient.get(getPropertyPortalInfos(propertyId)).then(response => {
        setPropertyPortalInfos(response.data["hydra:member"])
    })
    const {smoobuProperties, getSmoobuProperties} = useSmoobuProperties()

    useEffect(()=>{
        // @ts-ignore
        getPropertyPortalInfo(property.id)
    },[property.id])



    useEffect(() => {
        getSmoobuProperties(currentUser.company);
    }, [currentUser, property.smoobuId]);

    const onSubmit = (values:any) => {
        saveProperty(values).then(response => updateProperty()).catch((response) => toast.error("An error happened"))
    }

    const removeSmoobuId = () => {
        // @ts-ignore
        saveProperty({...property, smoobuId: null}).then(response => updateProperty()).catch((response) => toast.error("An error happened"))
    }

    const options = useMemo(()=>{
        return smoobuProperties.map(ap => {
            return {
                label:ap.name,
                value: ap.id
            }
        })
    },[smoobuProperties])

    const existingValue = useMemo(()=>{
        return options.find(op => op.value === property.smoobuId)
    },[options, property])

    return <div>
        <h2>Smoobu</h2>

        {property.smoobuId === undefined && <FormGeneratorContextProvider existingValue={property} onSubmit={onSubmit} formDescriptor={smoobuFormDescriptor}>
            <FormGeneratorContext.Consumer>
                {() => {
                    return <>
                        <FormElement accessor={"smoobuId"} options={options}></FormElement>
                        <Button variant="contained" type="submit">Save Smooubu Id</Button>
                    </>
                }}
            </FormGeneratorContext.Consumer>
        </FormGeneratorContextProvider>}

        {
            property.smoobuId !== undefined && <>
                <p>SmoobuId : {existingValue?.label}</p>
                <Button variant="contained" onClick={()=>{
                    removeSmoobuId()
                }}>Remove Smoobu Id</Button>
            </>
        }


        {propertyPortalInfos.map(propertyPortalInfo => {
            return <React.Fragment key={propertyPortalInfo.id}>
                <h3>{propertyPortalInfo.portalSubscription.portal.name}</h3>
                <div>{propertyPortalInfo.propertyPortalId}</div>
             {/*   <div>{propertyPortalInfo.roomPortalInfos.map(roomPortalInfo=><div>{roomPortalInfo.roomPortalId}</div>)}</div>*/}
            </React.Fragment>
        })}
    </div>
}
