import {Button, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Switch} from "@mui/material";
import React, {useEffect, useState} from "react";
import authClient from "../../../../api/authClient";
import {listings} from "../../../../router/api-routes";
import {toast} from "react-toastify";
import {SelectOption} from "../../../../components/GenericSelect/GenericSelect";
import {FormGeneratorContext, FormGeneratorContextProvider} from "form-generator";
import 'react-datepicker/dist/react-datepicker.css';
import propertyAmenitiesFormDescriptor from "../../../../forms/propertyAmenitiesFormType";
import {editProperty} from "../../../../api/property/propertyApi";
import {PropertyDTO} from "../../../../api/property/dto/PropertyDTO";
import {PropertyPatchDTO} from "../../../../api/property/dto/PropertyPatchDTO";

interface SelectItem{
    id: string,
    label:string
}

interface EditPropertyInterface{
    property:PropertyDTO
}

export default function PropertyAmenitiesTab({property}:EditPropertyInterface){

    const [propertyAmenities, setPropertyAmenities] = useState<SelectOption[]>([])
    const getPropertyAmenities = ()=> authClient.post(listings, {resources: {"property_amenities":[]}})
    useEffect(()=>{getPropertyAmenities().then(response => setPropertyAmenities((response.data.property_amenities).map((propertyType:SelectItem) => {
        return {
            label:propertyType.label,
            value:parseInt(propertyType.id),
            formValue: propertyType.id
        }
    })))},[])

    const onSubmit = (values:any) => {
        console.log("values",values)
        // @ts-ignore
        authClient.patch(editProperty(property.id), new PropertyPatchDTO(values))
            .then(()=>toast.success('Property saved', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }))
            .catch(()=>toast.error('An error occurred', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }))
    }



    return <FormGeneratorContextProvider existingValue={property} formDescriptor={propertyAmenitiesFormDescriptor} onSubmit={onSubmit}>
        <FormGeneratorContext.Consumer>
            {({values,isValid,errors,setFieldValue})=>{
                return <Grid container>
                        {
                            propertyAmenities.map((amenity:SelectOption) => {
                                // @ts-ignore
                                const index = values.amenities.findIndex(propertyAmenity=> propertyAmenity.id.toString() === amenity.value.toString())
                                const isChecked = index !== -1
                                return  <Grid item xs={3}>
                                    <FormControlLabel
                                    control={
                                        <Switch checked={isChecked} onChange={()=>{
                                            const newAmenities = (isChecked) ? values.amenities.filter((el:any) => el.id!==amenity.value) : [...values.amenities, {name:amenity.label, id:amenity.value}];
                                            setFieldValue(`amenities`, newAmenities)
                                        }} name={amenity.label} />
                                    }
                                    label={amenity.label}
                                /></Grid>
                            })}
                    <Grid className="mt-3" xs={12} item><Button variant="contained" type="submit">Save</Button></Grid>
                </Grid>

            }}</FormGeneratorContext.Consumer>
    </FormGeneratorContextProvider>
}
