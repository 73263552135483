import {Property} from "./Property";

export interface RoomInterface {
    id:number;
    description:string;
    title:string;
    uuid:string;
    video:string;
    view360:string;
    weeklyPrice:number
    allImages:Image[],
    roomType:string,
    property?: Property,
    active:boolean
    availableFrom?:string
    activeStartDate?:string
    activeEndDate?:string
    boosted?:boolean;
    boostedStartDate?:string
    boostedEndDate?:string
    latitude?:string
    longitude?:string
    amenities:any[]
    allAmenities:any[]
}

export class Room{
    id:number
    description:string
    title:string
    availableFrom?:string
    uuid:string
    video:string
    view360:string
    weeklyPrice:number
    allImages:Image[]
    roomType: number
    property?: Property
    active:boolean
    activeStartDate?:string
    activeEndDate?:string
    boosted?:boolean
    boostedStartDate?:string
    boostedEndDate?:string
    latitude?:string
    longitude?:string
    amenities:any[]
    allAmenities:any[]

    constructor({id, roomType,latitude,longitude, description, title, availableFrom, uuid,video,view360,weeklyPrice,allImages,property,active, activeStartDate, activeEndDate, boosted, boostedEndDate, boostedStartDate, amenities,allAmenities}:RoomInterface) {
        this.id = id;
        this.description = description;
        this.title = title;
        this.availableFrom= availableFrom;
        this.uuid = uuid;
        this.video = video;
        this.view360 = view360;
        this.weeklyPrice = weeklyPrice;
        this.allImages = allImages;
        this.property= property;
        this.active= active;
        this.activeStartDate= activeStartDate;
        this.activeEndDate= activeEndDate;
        this.boosted= boosted;
        this.roomType = parseInt(roomType);
        this.boostedStartDate = boostedStartDate;
        this.boostedEndDate = boostedEndDate
        this.latitude = latitude
        this.longitude = longitude
        this.amenities = amenities
        this.allAmenities = allAmenities
    }

    isEligibleForPublication():boolean{
        const hasTitle = this.title!==undefined;
        const hasDescription = this.description!==undefined;
        const hasImages = this.allImages.length >0;
        const hasWeeklyPrice = this.weeklyPrice > 0;
        const hasAvailableFrom = this.availableFrom !== undefined;
        const hasLatitude = this.latitude !== undefined
        const hasLongitude = this.longitude !== undefined
        return hasTitle && hasDescription && hasImages && hasWeeklyPrice && hasAvailableFrom && hasLatitude && hasLongitude
    }

}

export interface Image {
    id:number;
    filename:string;
    path:string;
    url:string;
    position:number
}
