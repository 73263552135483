import authClient from "../authClient";
import useCurrentUser from "../../authentication/useCurrentUser";
import {useDispatch} from "react-redux";
import {setCompany} from "../../redux/authSlice";
import {AUTH_ENTRYPOINT} from "../../router/api-routes";

const getCompanyInfoRoute = (id:string) => AUTH_ENTRYPOINT + `/api/companies/${id}`

export default function(){
    const currentUser = useCurrentUser()
    const dispatch = useDispatch()
    const getCompanyInfo = () => {
        return authClient.get(getCompanyInfoRoute(currentUser.company)).then(response =>{
            console.log("company info", response.data)
            dispatch(setCompany(response.data))
            return response
        })
    }

    return {getCompanyInfo}
}
