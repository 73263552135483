import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import authClient, {addQueryParams} from "../../../api/authClient";
import {getBookings, getEnquiries} from "../../../router/api-routes";
import {Enquiry, EnquiryInterface} from "../../../models/Enquiry";
import BookingsTable from "./BookingsTable";
import {Button, Card, CardContent, Grid} from "@mui/material";
import {FormElement, FormGeneratorContext, FormGeneratorContextProvider} from "form-generator";
import bookingsFilterFormDescriptor from "./bookingsFilterFormType";
import {Booking, BookingInterface} from "../../../models/Booking";
import useCurrentUser from "../../../authentication/useCurrentUser";


export default function Bookings(){

    const [bookings, setBookings] = useState<Booking[]>([]);
    const navigate = useNavigate();
    const [filters,setFilters] = useState({})
    const currentUser = useCurrentUser()

    useEffect(()=>{
        authClient
            .get(addQueryParams(getBookings(currentUser.company),filters))
            .then(response => setBookings(response.data.map((enquiry:BookingInterface)  => new Booking(enquiry)
        )))
    },[filters])


    return <>
        <Card>
            <CardContent>
                <FormGeneratorContextProvider formDescriptor={bookingsFilterFormDescriptor} onSubmit={(e) => setFilters(e)}>
                    <FormGeneratorContext.Consumer>
                        {()=>{
                            return <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormElement accessor={"surname"}></FormElement>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormElement accessor={"email"}></FormElement>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type={"submit"}>Search</Button>
                                </Grid>

                            </Grid>
                        }}
                    </FormGeneratorContext.Consumer>
                </FormGeneratorContextProvider>
            </CardContent>
        </Card>
        <BookingsTable bookings={bookings}/>
        </>
}
