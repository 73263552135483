import React from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import SignIn from "./authentication/Signin";
import AdsList from "./views/agent/Ads/AdsList";
import {
  account,
  admin, adminCompanies, bookings,
  enquiries,
  myads,
  newAd,
  newProperty, newUser,
  properties, users,
  weroomsManagedProperties
} from "./router/frontend-routes";
import AdCreate from "./views/agent/Ads/AdCreate";
import AdEdit from "./views/agent/Ads/AdEdit";
import {authProvider} from "./authentication/authProvider";
import SignUp from "./authentication/SignUp";
import Toast from "./views/agent/Dashboard/Toast";
import Enquiries from "./views/agent/Enquiries/Enquiries";
import Account from './views/agent/Account/Account';
import {Provider} from "react-redux";
import {store} from "./redux/store";
import PropertiesList from "./views/agent/Properties/PropertiesList";
import NewProperty from "./views/agent/Properties/NewProperty";
import EditProperty from './views/agent/Properties/EditProperty';
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme/Theme";
import {FormGeneratorThemeProvider} from 'form-generator';
import AgentDashboard from "./views/agent/Dashboard/AgentDashboard";
import WeroomsAgentDashboard from "./views/werooms_agent/WeroomsAgentDashboard";
import AdminDashboard from "./views/admin/AdminDashboard";
import Companies from "./views/admin/companies/Companies";
import ViewEnquiry from "./views/agent/Enquiries/ViewEnquiry";
import ViewCompany from "./views/admin/companies/ViewCompany";
import 'react-datepicker/dist/react-datepicker.css';
import UsersTable from "./views/agent/Users/Users";
import EditUser from "./views/agent/Users/EditUser";
import NewUser from "./views/agent/Users/NewUser";
import Bookings from "./views/agent/Bookings/Bookings";
import ViewBooking from "./views/agent/Bookings/ViewBooking";
import {UIKitSettingsBuilder} from "@cometchat/uikit-shared";
import {CometChatUIKit} from "@cometchat/chat-uikit-react";

const defaultRoutes = <>
  <Route path="/signin" element={<SignIn />} />
  <Route path="/signup" element={<SignUp />} />
  <Route path="*" element={<Navigate replace to="/signin"/>} />
</>

const adminRoutes = <Routes>
  <Route path={admin} element={<AdminDashboard content={<Companies></Companies>}/>}/>
  <Route path={adminCompanies} element={<AdminDashboard content={<Companies></Companies>}/>}/>
  <Route path={"/companies/:id"} element={<AdminDashboard content={<ViewCompany></ViewCompany>}/>}/>
  {defaultRoutes}
</Routes>

const superWeroomsAgentRoutes = <Routes>
  <Route path={weroomsManagedProperties} element={<WeroomsAgentDashboard content={<AdsList/>}/>} />
  <Route path={newAd} element={<WeroomsAgentDashboard content={<AdCreate/>}/>} />
  <Route path={enquiries} element={<WeroomsAgentDashboard content={<Enquiries/>}/>} />
  <Route path={bookings} element={<WeroomsAgentDashboard content={<Bookings/>}/>} />
  <Route path={properties} element={<WeroomsAgentDashboard content={<PropertiesList/>}/>} />
  <Route path={newProperty} element={<WeroomsAgentDashboard content={<NewProperty/>}/>} />
  <Route path={account} element={<WeroomsAgentDashboard content={<Account/>}/>} />
  <Route path={"/api/rooms/:id"} element={<WeroomsAgentDashboard content={<AdEdit/>}/>} />
  <Route path={"/properties/:id/edit"} element={<WeroomsAgentDashboard content={<EditProperty/>}/>} />
  <Route path={"/enquiries/:id"} element={<WeroomsAgentDashboard content={<ViewEnquiry/>}/>} />
  <Route path={"/bookings/:id"} element={<WeroomsAgentDashboard content={<ViewBooking/>}/>} />
  {defaultRoutes}
</Routes>

const weroomsAgentRoutes = <Routes>
  <Route path={weroomsManagedProperties} element={<WeroomsAgentDashboard content={<AdsList/>}/>} />
  <Route path={newAd} element={<WeroomsAgentDashboard content={<AdCreate/>}/>} />
  <Route path={enquiries} element={<WeroomsAgentDashboard content={<Enquiries/>}/>} />
  <Route path={bookings} element={<WeroomsAgentDashboard content={<Bookings/>}/>} />
  <Route path={properties} element={<WeroomsAgentDashboard content={<PropertiesList/>}/>} />
  <Route path={newProperty} element={<WeroomsAgentDashboard content={<NewProperty/>}/>} />
  <Route path={account} element={<WeroomsAgentDashboard content={<Account/>}/>} />
  <Route path={"/api/rooms/:id"} element={<WeroomsAgentDashboard content={<AdEdit/>}/>} />
  <Route path={"/properties/:id/edit"} element={<WeroomsAgentDashboard content={<EditProperty/>}/>} />
  <Route path={"/enquiries/:id"} element={<WeroomsAgentDashboard content={<ViewEnquiry/>}/>} />
  <Route path={"/bookings/:id"} element={<WeroomsAgentDashboard content={<ViewBooking/>}/>} />
  {defaultRoutes}
</Routes>

const standardAdminAgentRoutes = <Routes>
  <Route path={myads} element={<AgentDashboard content={<AdsList/>}/>} />
  <Route path={newAd} element={<AgentDashboard content={<AdCreate/>}/>} />
  <Route path={enquiries} element={<AgentDashboard content={<Enquiries/>}/>} />
  <Route path={bookings} element={<AgentDashboard content={<Bookings/>}/>} />
  <Route path={properties} element={<AgentDashboard content={<PropertiesList/>}/>} />
  <Route path={newProperty} element={<AgentDashboard content={<NewProperty/>}/>} />
  <Route path={account} element={<AgentDashboard content={<Account/>}/>} />
  <Route path={users} element={<AgentDashboard content={<UsersTable/>}/>} />
  <Route path={"/users/:id"} element={<AgentDashboard content={<EditUser/>}/>} />
  <Route path={newUser} element={<AgentDashboard content={<NewUser/>}/>} />
  <Route path={"/api/rooms/:id"} element={<AgentDashboard content={<AdEdit/>}/>} />
  <Route path={"/properties/:id/edit"} element={<AgentDashboard content={<EditProperty/>}/>} />
  <Route path={"/enquiries/:id"} element={<AgentDashboard content={<ViewEnquiry/>}/>} />
  <Route path={"/bookings/:id"} element={<AgentDashboard content={<ViewBooking/>}/>} />
  <Route path="/" element={<AgentDashboard content={<Toast/>} />} />
  {defaultRoutes}
</Routes>


const standardAgentRoutes = <Routes>
  <Route path={myads} element={<AgentDashboard content={<AdsList/>}/>} />
  <Route path={newAd} element={<AgentDashboard content={<AdCreate/>}/>} />
  <Route path={enquiries} element={<AgentDashboard content={<Enquiries/>}/>} />
  <Route path={bookings} element={<AgentDashboard content={<Bookings/>}/>} />
  <Route path={properties} element={<AgentDashboard content={<PropertiesList/>}/>} />
  <Route path={newProperty} element={<AgentDashboard content={<NewProperty/>}/>} />
  <Route path={account} element={<AgentDashboard content={<Account/>}/>} />
  <Route path={"/api/rooms/:id"} element={<AgentDashboard content={<AdEdit/>}/>} />
  <Route path={"/properties/:id/edit"} element={<AgentDashboard content={<EditProperty/>}/>} />
  <Route path={"/enquiries/:id"} element={<AgentDashboard content={<ViewEnquiry/>}/>} />
  <Route path={"/bookings/:id"} element={<AgentDashboard content={<ViewBooking/>}/>} />
  <Route path="/" element={<AgentDashboard content={<PropertiesList/>}  />} />
  {defaultRoutes}
</Routes>

const unauthenticatedRoutes = <Routes>
  {defaultRoutes}
</Routes>

const getRoutesByRole = (tokenValid:boolean,roles:string[],isAdmin:boolean) => {
  if(!tokenValid) return unauthenticatedRoutes;
  if(roles.includes("ROLE_ADMIN")) return adminRoutes;
  if(roles.includes("ROLE_SUPER_WEROOMS")) return superWeroomsAgentRoutes;
  if(roles.includes("ROLE_WEROOMS")) return weroomsAgentRoutes;
  if(roles.includes("ROLE_USER") && isAdmin) return standardAdminAgentRoutes;
  if(roles.includes("ROLE_USER")) return standardAgentRoutes;
  return unauthenticatedRoutes
}

export const getDefaultRouteByRole = (roles:string[]) => {
  if(roles.includes("ROLE_ADMIN")) return admin;
  if(roles.includes("ROLE_SUPER_WEROOMS")) return weroomsManagedProperties;
  if(roles.includes("ROLE_WEROOMS")) return weroomsManagedProperties;
  if(roles.includes("ROLE_USER")) return myads;
  return "/signin"
}

const COMETCHAT_CONSTANTS = {
  APP_ID: "252729f99e7cfcbe", //Replace with your App ID
  REGION: "eu", //Replace with your App Region
  AUTH_KEY: "4fc7706abf7d13ed0bc33f21c55c1b918cd5f8eb" //Replace with your Auth Key
}

//create the builder
const UIKitSettings = new UIKitSettingsBuilder()
    .setAppId(COMETCHAT_CONSTANTS.APP_ID)
    .setRegion(COMETCHAT_CONSTANTS.REGION)
    .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
    .subscribePresenceForFriends()
    .build();

//Initialize CometChat UIKit
// @ts-ignore
CometChatUIKit.init(UIKitSettings).then(() => {
  console.log("Initialization completed successfully");
  // You can now call login function.
}).catch(console.log);

function App() {
  const userRoles:string[] = authProvider.getRoles();
  const tokenValid = authProvider.checkTokenValidity()
  const isAdmin = authProvider.isAdmin()

  return <Provider store={store}>
    <FormGeneratorThemeProvider theme={"material-ui"}>
      <ThemeProvider theme={theme}>
          <BrowserRouter>
            {getRoutesByRole(tokenValid,userRoles,isAdmin)}
          </BrowserRouter>
      </ThemeProvider>
    </FormGeneratorThemeProvider>
  </Provider>
}

export default App;
