import {Property} from "./Property";
import {BookingPaymentStatus} from "./BookingPaymentStatus";

export interface BookingInterface{
    id:number,
    name: string,
    surname: string,
    email: string,
    phoneNumber:string,
    property: Property,
    startDate?:string,
    endDate?:string,
    createdAt?:string
    paymentStatus?:number
    paymentReferenceId?:string
    cometChatGroupId?:string

}

export class Booking{
    id:number
    name: string
    surname: string
    email: string
    phoneNumber:string
    property: Property
    createdAt?:string
    startDate?:string
    endDate?:string
    cometChatGroupId?:string
    paymentStatus:BookingPaymentStatus

    paymentReferenceId?:string

    constructor({id,name,surname,email,phoneNumber,property,cometChatGroupId, startDate, endDate,paymentReferenceId,paymentStatus,createdAt}:BookingInterface) {
        this.id = id;
        this.name = name;
        this.surname = surname;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.property = property
        this.startDate = startDate;
        this.endDate = endDate
        this.paymentReferenceId = paymentReferenceId
        this.paymentStatus = new BookingPaymentStatus({status:paymentStatus})
        this.createdAt = createdAt
        this.cometChatGroupId = cometChatGroupId
    }
}



interface Room {
    id: number,
    title: string,
}
