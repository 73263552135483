import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import authClient from "../../../api/authClient";
import {getBookingRoute} from "../../../router/api-routes";
import Container from "@mui/material/Container";
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import moment from "moment";
import {Booking} from "../../../models/Booking";
import { CometChatMessages } from "@cometchat/chat-uikit-react";
import {CometChat, Group} from "@cometchat/chat-sdk-javascript";

export default function ViewBooking(){
    const params = useParams()
    const [enquiry, setEnquiry] = useState<Booking|undefined>()

    const getBooking = () => authClient.get(getBookingRoute(params.id)).then(response => setEnquiry(new Booking(response.data)))
    const paymentReferenceId = enquiry?.paymentReferenceId

    const [chatWithGroup, setChatWithGroup] = React.useState<Group|null>(null);
    React.useEffect(() => {
        if(enquiry?.cometChatGroupId){
            CometChat.getGroup(enquiry.cometChatGroupId).then((group) => {
                setChatWithGroup(group);
            });
        }
    }, [enquiry?.cometChatGroupId]);


    useEffect(()=>{
        getBooking()
    },[params.id])

    return <Container>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card className={"mb-3"}>
                    <CardHeader
                        title={`Enquiry by ${enquiry?.name} ${enquiry?.surname}`}
                        subheader={enquiry?.createdAt && `Received: ${moment(enquiry.createdAt).format("MMMM Do YYYY")}`}
                    />
                </Card>
                <Card className={"mb-3"}>
                    <CardHeader title={`Prospective Tenant`}/>
                    <CardContent>
                        <p>Name: {enquiry?.name}</p>
                        <p>Surname: {enquiry?.surname}</p>
                        <p>Email: {enquiry?.email}</p>
                        <p>Phone number: {enquiry?.phoneNumber}</p>
                    </CardContent>
                </Card>
                <Card className={"mb-3"}>
                    <CardHeader title={`Chat`}/>
                    {chatWithGroup!==null && <CometChatMessages group={chatWithGroup}></CometChatMessages>}
                </Card>
            </Grid>
        </Grid>
    </Container>
}
